export default class {
    constructor({
        id,
        labelHandle,
        inputHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const label = el.querySelector(labelHandle);
        const input = el.querySelector(inputHandle);

        // Event handler functions
        function handleCheck() {
            // If input is textarea set height
            if (input.nodeName === 'TEXTAREA') {
                input.style.height = '';
                input.style.height = `${input.scrollHeight + 1}px`;
            }

            // If input is empty remove active class
            if (!input.value.trim()) {
                el.classList.remove(activeClass);

                return;
            }

            el.classList.add(activeClass);
        }

        // Add event listeners
        label.addEventListener('click', handleCheck);
        input.addEventListener('focus', handleCheck);
        input.addEventListener('blur', handleCheck);
        input.addEventListener('input', handleCheck);

        // Initialize
        handleCheck();
    }
}
