export default {
    midwest: {
        img: '/images/projects/region-midwest.svg',
        north: 49.60465435063083,
        south: 35.64224875744254,
        east: -80.4709956753265,
        west: -104.05261236843992,
    },
    northeast: {
        img: '/images/projects/region-northeast.svg',
        north: 47.458918419854896,
        south: 38.95355211875751,
        east: -65.9690425503265,
        west: -81.38781744656492,
    },
    southeast: {
        img: '/images/projects/region-southeast.svg',
        north: 40.952787172649316,
        south: 24.250114571057676,
        east: -75.01476323151995,
        west: -94.64831549343992,
    },
    southwest: {
        img: '/images/projects/region-southwest.svg',
        north: 38.34796295376381,
        south: 24.29017588926562,
        east: -93.47179448151995,
        west: -114.86315924343992,
    },
    west: {
        img: '/images/projects/region-west.svg',
        north: 50.730540557233304,
        south: 30.26205128507418,
        east: -101.9602534878265,
        west: -124.79479986843992,
    },
};
