import MarkerClusterer from '@googlemaps/markerclustererplus';
import MapCard from './projects-map-card';
import MapRegions from './projects-map-regions';
import MapStyles from './projects-map-styles';
import ProjectModalLinks from './project-modal-links';
import GoogleMapsLoader from 'google-maps';
import { gApiKey } from '../config';

GoogleMapsLoader.KEY = gApiKey;

const classNoResults = 'no-results';
const classShowMap = 'show-map';
const classUseRegion = 'use-region';

const zipRange = 100; // in miles

const defaultCenterLat = 37.0902;
const defaultCenterLng = -95.7129;
const defaultZoom = 3;

const SEARCH_ZIP = 0;
const SEARCH_REGION = 1;
const SEARCH_ROOM = 2;

function calculateDistance(lat1, lng1, lat2, lng2) {
    if (lat1 === lat2 && lng1 === lng2) {
        return 0;
    }
    const radLat1 = Math.PI * lat1 / 180;
    const radLat2 = Math.PI * lat2 / 180;
    const theta = lng1 - lng2;
    const radTheta = Math.PI * theta / 180;
    const p1 = Math.sin(radLat1);
    const p2 = Math.sin(radLat2);
    let dist = p1 * p2 + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
}

export default class {
    constructor({
        id,
        data,
        formHandle,
        mapHandle,
        cardsHandle,
        resultsHandle,
        listButtonHandle,
        mapButtonHandle,
        regionInputHandle,
        regionLabelHandle,
        roomSelectHandle,
        resultsTitleHandle,
        showInRegionButtonHandle,
    }) {
        this.data = data;
        this.el = document.getElementById(id);

        this.google = null;
        this.geocoder = null;
        this.map = null;
        this.cards = [];
        this.markers = [];
        this.markerListeners = [];
        this.overlays = {};
        this.results = [];
        this.search = SEARCH_REGION;
        this.searchFor = '';

        let i;
        let l;

        // Misc Elements
        this.resultsTitle = this.el.querySelector(resultsTitleHandle);
        this.cardsContainer = this.el.querySelector(cardsHandle);
        this.buttonShowInRegionClick = this.buttonShowInRegionClick.bind(this);
        this.el.querySelector(showInRegionButtonHandle).addEventListener('click', this.buttonShowInRegionClick);
        this.mapContainer = this.el.querySelector(mapHandle);
        this.resultsContainer = this.el.querySelector(resultsHandle);
        this.projectLinks = new ProjectModalLinks(this.el);

        // Toggle View Buttons
        this.buttonToggleClick = this.buttonToggleClick.bind(this);
        this.el.querySelector(listButtonHandle).addEventListener('click', this.buttonToggleClick);
        this.el.querySelector(mapButtonHandle).addEventListener('click', this.buttonToggleClick);

        // Region Inputs
        this.region = '';
        this.regionInputs = Array.prototype.slice.call(
            this.el.querySelectorAll(regionInputHandle),
            0,
        );
        this.regionChange = this.regionChange.bind(this);
        for (i = 0, l = this.regionInputs.length; i < l; i += 1) {
            this.regionInputs[i].addEventListener('change', this.regionChange);
            if (this.regionInputs[i].checked) {
                this.region = this.regionInputs[i].value;
            }
        }
        const regionLabels = Array.prototype.slice.call(
            this.el.querySelectorAll(regionLabelHandle),
            0,
        );
        for (i = 0, l = regionLabels.length; i < l; i += 1) {
            regionLabels[i].addEventListener('click', this.buttonShowInRegionClick);
        }

        // ZIP Input
        this.zipInput = this.el.querySelector('.input__input--search');
        this.zip = this.zipInput.value;
        if (this.zip) {
            this.search = SEARCH_ZIP;
        }
        this.formSubmit = this.formSubmit.bind(this);
        this.el.querySelector(formHandle).addEventListener('submit', this.formSubmit);

        // Room Select
        this.roomSelect = this.el.querySelector(roomSelectHandle);
        this.room = this.roomSelect.value;
        this.roomChange = this.roomChange.bind(this);
        this.roomSelect.addEventListener('change', this.roomChange);
        if ('URLSearchParams' in window) {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('room')) {
                this.search = SEARCH_ROOM;
            }
        }

        // Room Names
        const roomNames = {};
        const roomOptions = Array.prototype.slice.call(this.roomSelect.querySelectorAll('option'), 0);
        roomOptions.forEach((option) => {
            const key = option.getAttribute('value');
            const val = option.innerHTML;
            if (key && val) {
                roomNames[key] = val;
            }
        });
        this.roomNames = roomNames;

        // Update Results
        this.updateResults(true);

        // Load Google Maps
        this.googleMapsLoaded = this.googleMapsLoaded.bind(this);
        this.getZipGeocodeComplete = this.getZipGeocodeComplete.bind(this);
        this.mapClicked = this.mapClicked.bind(this);
        this.markerClicked = this.markerClicked.bind(this);
        GoogleMapsLoader.load(this.googleMapsLoaded);

        // History
        this.historyPopState = this.historyPopState.bind(this);
        window.addEventListener('popstate', this.historyPopState);
    }

    googleMapsLoaded(google) {
        this.google = google;
        this.geocoder = new this.google.maps.Geocoder();

        this.map = new this.google.maps.Map(this.mapContainer, {
            center: new this.google.maps.LatLng(defaultCenterLat, defaultCenterLng),
            zoom: defaultZoom,
            fullscreenControl: false,
            gestureHandling: 'greedy',
            mapTypeControl: false,
            scrollwheel: true,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: this.google.maps.ControlPosition.RIGHT_TOP,
            },
            styles: MapStyles,
        });

        this.markerImage = new this.google.maps.MarkerImage(
            '/images/projects/map-marker.png',
            new this.google.maps.Size(26, 43), // size
            new this.google.maps.Point(0, 0), // origin
            new this.google.maps.Point(13, 43), // anchor
            new this.google.maps.Size(26, 43) // scaled size
        );

        this.markerClustererStyles = [
            MarkerClusterer.withDefaultStyle({
                className: 'marker-cluster-icon',
                height: 44,
                textColor: '#ffffff',
                textSize: 15,
                width: 44,
                url: '/images/projects/map-cluster.png',
            }),
        ];

        this.infoWindow = new this.google.maps.InfoWindow();
        this.google.maps.event.addListener(this.map, 'click', this.mapClicked);

        // create region overlay pseudo class
        function RegionOverlay(bounds, image) {
            this.bounds_ = bounds;
            this.image_ = image;
            this.div_ = null;
        }
        RegionOverlay.prototype = new this.google.maps.OverlayView();
        RegionOverlay.prototype.onAdd = function() {
            var div = document.createElement('div');
            div.style.borderStyle = 'none';
            div.style.borderWidth = '0';
            div.style.position = 'absolute';
            var img = document.createElement('img');
            img.src = this.image_;
            img.style.height = '100%';
            img.style.opacity = '1';
            img.style.position = 'absolute';
            img.style.width = '100%';
            div.appendChild(img);
            this.div_ = div;
            var panes = this.getPanes();
            panes.overlayLayer.appendChild(div);
        };
        RegionOverlay.prototype.draw = function() {
            var overlayProjection = this.getProjection();
            var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
            var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());
            var div = this.div_;
            div.style.left = sw.x + 'px';
            div.style.top = ne.y + 'px';
            div.style.width = (ne.x - sw.x) + 'px';
            div.style.height = (sw.y - ne.y) + 'px';
        };
        RegionOverlay.prototype.updateBounds = function(bounds){
          this.bounds_ = bounds;
          this.draw();
        };
        RegionOverlay.prototype.onRemove = function() {
          this.div_.parentNode.removeChild(this.div_);
          this.div_ = null;
        };

        for (let key in MapRegions) {

            this.overlays[key] = new RegionOverlay(
                new this.google.maps.LatLngBounds(
                    new this.google.maps.LatLng(MapRegions[key].south, MapRegions[key].west),
                    new this.google.maps.LatLng(MapRegions[key].north, MapRegions[key].east)
                ),
                MapRegions[key].img
            );

            /*
            this.overlays[key] = new google.maps.GroundOverlay(
                MapRegions[key].img,
                {
                    north: MapRegions[key].north,
                    south: MapRegions[key].south,
                    east: MapRegions[key].east,
                    west: MapRegions[key].west,
                }
            );
            */

        }

        this.searchFor = '';
        this.updateResults(false);
    }

    buttonShowInRegionClick() {
        // NOTE: the setTimeout is to prevent results from updating unnecessarily
        const self = this;
        setTimeout(() => {
            self.search = SEARCH_REGION;
            self.updateResults(true);
        }, 100);
    }

    buttonToggleClick(event) {
        event.preventDefault();
        this.el.classList.toggle(classShowMap);

        // force the results to update when toggled to the map view ... to resolve Google Maps zoom bug on mobile
        this.searchFor = 'FORCE UPDATE';
        this.updateResults(false);
    }

    regionChange(event) {
        this.search = SEARCH_REGION;
        this.region = event.currentTarget.value;

        this.room = '';
        this.roomSelect.value = '';

        this.updateResults(true);
    }

    roomChange(event) {
        this.room = event.currentTarget.value;
        if (this.room) {
            this.search = SEARCH_ROOM;
        } else {
            this.search = SEARCH_REGION;
        }
        this.updateResults(true);
    }

    formSubmit(event) {
        event.preventDefault();
        this.zip = this.zipInput.value;
        if (this.zip) {
            this.search = SEARCH_ZIP;
        } else {
            this.search = SEARCH_REGION;
        }

        this.room = '';
        this.roomSelect.value = '';

        this.updateResults(true);
    }

    updateResults(pushHistory) {
        // only update results when search value is different
        let searchFor = this.zip;
        if (this.search === SEARCH_REGION) {
            searchFor = this.region;
        } else if (this.search === SEARCH_ROOM) {
            searchFor = this.room;
        }
        if (this.searchFor === searchFor) {
            return;
        }
        this.searchFor = searchFor;

        // clean up
        this.removeResults();

        // update results
        if (this.search === SEARCH_REGION) {
            this.results = this.filterByRegion(this.region);
            this.el.classList.add(classUseRegion);
            this.resultsTitle.innerHTML = `Results (${this.results.length})`;
            this.displayResults();
        } else if (this.search === SEARCH_ROOM) {
            this.results = this.filterByRoom(this.room);
            this.el.classList.remove(classUseRegion);
            const roomName = (this.roomNames[this.room]) ? this.roomNames[this.room] : 'Room';
            this.resultsTitle.innerHTML = `Results for ${roomName} (${this.results.length})`;
            this.displayResults();
        } else {
            if (this.google === null) {
                this.searchFor = '';
            }
            this.results = [];
            this.el.classList.remove(classUseRegion);
            this.resultsTitle.innerHTML = `Results for ZIP code “${this.zip}”`;
            this.filterByZip(this.zip);
        }

        // Update query string in address bar
        if (pushHistory && 'URLSearchParams' in window) {
            const searchParams = new URLSearchParams(window.location.search);
            if (this.search === SEARCH_REGION) {
                searchParams.set('region', this.region);
                searchParams.set('room', '');
                searchParams.set('zip', '');
            } else if (this.search === SEARCH_ROOM) {
                searchParams.set('region', '');
                searchParams.set('room', this.room);
                searchParams.set('zip', '');
            } else {
                searchParams.set('region', '');
                searchParams.set('room', '');
                searchParams.set('zip', this.zip);
            }
            const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
            window.history.pushState(null, '', newRelativePathQuery);
        }
    }

    removeResults() {
        this.el.classList.remove(classNoResults);

        // remove overlays
        for (let key in this.overlays) {
            this.overlays[key].setMap(null);
        }

        // remove old cards
        this.cards.forEach(card => {
            card.destroy();
        });
        this.cards = [];

        // remove old markers
        if (this.markerClusterer) {
            this.markerClusterer.clearMarkers();
        }
        let i;
        let l;
        for (i = 0, l = this.markers.length; i < l; i += 1) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
        for (i = 0, l = this.markerListeners.length; i < l; i += 1) {
            this.google.maps.event.removeListener(this.markerListeners[i]);
        }
        this.markerListeners = [];
    }

    displayResults() {
        this.removeResults();
        if (this.search === SEARCH_REGION) {
            this.resultsTitle.innerHTML = `Results (${this.results.length})`;
        } else if (this.search === SEARCH_ROOM) {
            const roomName = (this.roomNames[this.room]) ? this.roomNames[this.room] : 'Room';
            this.resultsTitle.innerHTML = `Results for ${roomName} (${this.results.length})`;
        } else {
            this.resultsTitle.innerHTML = `Results for ZIP code “${this.zip}” (${this.results.length})`;
        }
        if (this.results.length === 0) {
            this.el.classList.add(classNoResults);
        } else {
            this.el.classList.remove(classNoResults);
        }

        let i;
        let l;

        let card;
        let listener;
        let marker;
        let position;

        // create new cards
        for (i = 0, l = this.results.length; i < l; i += 1) {
            card = new MapCard(this.cardsContainer, this.results[i]);
            this.cards.push(card);
        }
        this.resultsContainer.scrollTop = 0;

        if (this.google) {
            const bounds = new this.google.maps.LatLngBounds();

            // set overlay
            if (this.search === SEARCH_REGION) {
                const overlay = this.overlays[this.region];
                if (overlay) {
                    overlay.setMap(this.map);
                }
            }

            // create new markers
            for (i = 0, l = this.results.length; i < l; i += 1) {
                position = new this.google.maps.LatLng(this.results[i].latitude, this.results[i].longitude);
                marker = new this.google.maps.Marker({
                    position: position,
                    map: this.map,
                    title: this.results[i].title,
                    icon: this.markerImage,
                });
                bounds.extend(position);
                listener = marker.addListener('click', this.markerClicked);
                this.markers.push(marker);
                this.markerListeners.push(listener);
            }
            this.markerClusterer = new MarkerClusterer(this.map, this.markers, { styles: this.markerClustererStyles });

            // center and zoom map
            const mapRegion = (this.search === SEARCH_REGION) ? MapRegions[this.region] : null;
            if (mapRegion) {
                bounds.extend(new this.google.maps.LatLng(mapRegion.north, mapRegion.west));
                bounds.extend(new this.google.maps.LatLng(mapRegion.south, mapRegion.east));
                this.map.fitBounds(bounds);
            } if (this.markers && this.markers.length > 0) {
                this.map.fitBounds(bounds);
            } else {
                this.map.setZoom(defaultZoom);
                this.map.panTo(this.google.maps.LatLng(defaultCenterLat, defaultCenterLng));
            }
        }


    }

    filterByRegion(region) {
        const filtered = [];
        this.data.forEach(item => {
            if (item.region === region) {
                filtered.push(item);
            }
        });
        return filtered;
    }

    filterByRoom(room) {
        const filtered = [];
        this.data.forEach(item => {
            if (item.rooms.indexOf(room) > -1) {
                filtered.push(item);
            }
        });
        return filtered;
    }

    filterByZip(zip) {
        if (this.google === null) {
            return;
        }
        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
        if (isValidZip) {
            this.geocoder.geocode({ address: zip }, this.getZipGeocodeComplete);
        } else {
            this.displayResults();
        }
    }

    getZipGeocodeComplete(results, status) {
        if (this.search === SEARCH_ZIP && status === this.google.maps.GeocoderStatus.OK) {
            const filtered = [];
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();
            this.data.forEach(item => {
                item.distance = calculateDistance(item.latitude, item.longitude, lat, lng);
                if (item.distance <= zipRange) {
                    filtered.push(item);
                }
            });
            filtered.sort((a, b) => {
                if (a.distance < b.distance) {
                    return -1;
                }
                return (a.distance > b.distance) ? 1 : 0;
            });
            this.results = filtered;
            this.displayResults();
        } else {
            this.displayResults();
        }
    }

    historyPopState() {
        if ('URLSearchParams' in window) {
            const searchParams = new URLSearchParams(window.location.search);
            const region = searchParams.get('region');
            const room = searchParams.get('room');
            const zip = searchParams.get('zip');
            if (region || room || zip) {

                this.search = SEARCH_REGION;

                if (region) {
                    let i;
                    let l;
                    let index = 0;
                    for (i = 0, l = this.regionInputs.length; i < l; i += 1) {
                        this.regionInputs[i].checked = false;
                        if (region === this.regionInputs[i].value) {
                            index = i;
                        }
                    }
                    this.regionInputs[index].checked = true;
                    this.region = this.regionInputs[index].value;
                }

                if (room) {
                    this.room = room;
                    this.roomSelect.value = room;
                    this.search = SEARCH_ROOM;
                } else {
                    this.room = '';
                    this.roomSelect.value = '';
                }

                if (zip) {
                    this.zip = zip;
                    this.zipInput.value = this.zip;
                    this.search = SEARCH_ZIP;
                } else {
                    this.zip = '';
                    this.zipInput.value = '';
                }

                this.updateResults(false);
            }
        }
    }

    mapClicked(event) {
        this.infoWindow.close();
    }

    markerClicked(event) {
        if (this.infoWindow) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            let index = -1;
            this.results.forEach((item, i) => {
                if (item.latitude === lat && item.longitude === lng) {
                    index = i;
                }
            });
            if (index !== -1) {
                const marker = this.markers[index];
                const project = this.results[index];
                if (project.images) {
                    this.infoWindow.setContent(`<a class="map-info-window" href="${project.url}" title="${project.title}"><div style="background-image:url('${project.images[0]}');"></div><span><strong>${project.title}</strong><em><i class="icon icon-compass-down"><svg><use href="/images/sprites/sprites.svg#icon-compass-down"></use></svg></i> ${project.location}</em></span></a>`);
                } else {
                    this.infoWindow.setContent(`<a class="map-info-window" href="${project.url}" title="${project.title}"><span><strong>${project.title}</strong><em><i class="icon icon-compass-down"><svg><use href="/images/sprites/sprites.svg#icon-compass-down"></use></svg></i> ${project.location}</em></span></a>`);
                }
                this.infoWindow.open(this.map, marker);
            }
        }
    }
}
