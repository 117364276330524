export default class {
    constructor({
        id,
        productHandle,
        prevHandle,
        nextHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const products = el.querySelectorAll(productHandle);
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);

        // State variables
        let activeIndex = 0;

        // Helper functions
        function setActiveProduct() {
            Array.from(products).forEach((product, i) => {
                if (i === activeIndex) {
                    product.classList.add(activeClass);
                } else {
                    product.classList.remove(activeClass);
                }
            });
        }

        // Event handler functions
        function handlePrev() {
            activeIndex--;

            activeIndex = activeIndex < 0 ? products.length - 1 : activeIndex;

            setActiveProduct();
        }
        function handleNext() {
            activeIndex++;

            activeIndex = activeIndex > products.length - 1 ? 0 : activeIndex;

            setActiveProduct();
        }

        // Add event listeners
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);
    }
}
