import { breakpoints } from '../config';

export function getBreakpoint() {
    const width = window.innerWidth;

    let breakpoint = 'sm';

    if (width > breakpoints.lg.min) {
        breakpoint = 'xl';
    } else if (width >= breakpoints.md.min) {
        breakpoint = 'lg';
    } else if (width >= breakpoints.sm.min) {
        breakpoint = 'md';
    }

    return breakpoint;
}

export function getNavOffset() {
    return getBreakpoint() === 'sm' ? 72 : 92;
}
