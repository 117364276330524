import Swiper, { Autoplay, Navigation } from 'swiper';
import debounce from '../utilities/debouncer';

Swiper.use([Autoplay, Navigation]);

export default class {
    constructor({
        id,
        testimonialTextHandle,
        prevHandle,
        nextHandle,
        counterHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const texts = el.querySelectorAll(testimonialTextHandle);
        const counters = el.querySelectorAll(counterHandle);

        let slider = null;

        const handleResize = debounce(() => {
            // Reset all heights
            Array.from(texts).forEach(t => { t.style.height = 'inherit'; });

            // Set all text containers to the max text height to avoid jumping of nav controls
            const max = Array.from(texts)
                .reduce((acc, cur) => Math.max(acc, cur.offsetHeight), 0);

            Array.from(texts).forEach(t => { t.style.height = `${max}px`; });
        }, 500);

        function handleInit() {
            texts[0].classList.add(activeClass);
            counters[0].classList.add(activeClass);
        }

        function handleSlideChange() {
            if (slider === null) {
                return;
            }

            Array.from(texts).forEach((t, i) => {
                t.classList.toggle(activeClass, i === slider.realIndex);
                counters[i].classList.toggle(activeClass, i === slider.realIndex);
            });
        }

        const handleCounters = Array.from(counters).map((c, i) => () => {
            if (slider === null) {
                return;
            }

            slider.slideToLoop(i);
        });

        window.addEventListener('resize', handleResize);
        Array.from(counters).forEach((c, i) => {
            c.addEventListener('click', handleCounters[i]);
        });

        slider = new Swiper('.swiper-container', {
            loop: true,
            navigation: {
                nextEl: nextHandle,
                prevEl: prevHandle,
            },
            autoplay: {
                delay: 12000,
                disableOnInteraction: false,
            },
            on: {
                init: handleInit,
                slideChange: handleSlideChange,
            },
        });
        handleResize();
    }
}
