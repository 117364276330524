import { events, emitEvent } from '../utilities/custom-events';
import { getBreakpoint } from '../utilities/breakpoints';

export default class {
    constructor({
        id,
        toggleInputHandle,
        clearHandle,
    }) {
        const el = document.getElementById(id);
        const toggleInput = el.querySelector(toggleInputHandle);
        const form = el.querySelector('form');
        const inputs = form.querySelectorAll('[type="checkbox"]');
        const clear = el.querySelector(clearHandle);

        function handleChange() {
            if (getBreakpoint() === 'sm' && toggleInput.checked) {
                emitEvent(events.lockScroll);
            } else {
                emitEvent(events.unlockScroll);
            }
        }
        function handleClick() {
            Array.from(inputs).forEach(input => { input.checked = false; });

            form.submit();
        }

        toggleInput.addEventListener('change', handleChange);
        clear.addEventListener('click', handleClick);
    }
}
