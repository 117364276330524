export default class {
    constructor({
        id,
        imageHandle,
        progressHandle,
        colorsListHandle,
        colorHandle,
        activeClass,
        fixedClass,
        pastClass,
        state,
    }) {
        const el = document.getElementById(id);
        const image = el.querySelector(imageHandle);
        const progresses = el.querySelectorAll(progressHandle);
        const colorsLists = el.querySelectorAll(colorsListHandle);
        const colors = el.querySelectorAll(colorHandle);

        // State variables
        let activeColor = 0;

        // Event handler functions
        function handleScroll() {
            const elRect = el.getBoundingClientRect();
            const imageRect = image.getBoundingClientRect();

            // Check if el is past top of window
            if (elRect.top - state.headerHeight < 0) {
                el.classList.add(fixedClass);
            } else {
                el.classList.remove(fixedClass);
            }
            // Check if bottom of image is at bottom of cont
            // Add offset to bottom check since image is absolutely positioned when past
            if (imageRect.bottom > elRect.bottom + 5 || imageRect.top < state.headerHeight - 5) {
                el.classList.add(pastClass);
            } else {
                el.classList.remove(pastClass);
            }
        }
        const handleScrolls = Array.from(colorsLists).map((cl, i) => () => {
            const progress = (cl.scrollLeft + cl.offsetWidth) / cl.scrollWidth;
            const scale = progress ** 4;

            progresses[i].style.transform = `scaleX(${scale})`;
        });
        const handleClicks = Array.from(colors).map((color, i) => () => {
            const imageSrc = color.getAttribute('data-image-src');

            colors[activeColor].classList.remove(activeClass);
            activeColor = i;
            color.classList.add(activeClass);

            image.style.backgroundImage = `url(${imageSrc})`;
        });

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        Array.from(colorsLists).forEach((cl, i) => {
            cl.addEventListener('scroll', handleScrolls[i]);
        });
        Array.from(colors).forEach((color, i) => {
            color.addEventListener('click', handleClicks[i]);
        });

        // Initialize
        handleScroll();
        // Wait for all colors in list to load then initialize scrollbar for colorsList
        Array.from(colorsLists).forEach((cl, i) => {
            const images = cl.querySelectorAll('img');

            let loaded = 0;

            Array.from(images).forEach(img => {
                img.addEventListener('load', () => {
                    loaded++;

                    if (loaded === images.length) handleScrolls[i]();
                });
            });
        });
    }
}
