import ProjectImages from './project-images';

const swiperButtons = ([
    '<button type="button" class="project-images__prev"><i class="icon icon-arrow-left"><svg>',
    '<use href="/images/sprites/sprites.svg#icon-arrow-left"></use>',
    '</svg></i></button>',
    '<button type="button" class="project-images__next"><i class="icon icon-arrow-right"><svg>',
    '<use href="/images/sprites/sprites.svg#icon-arrow-right"></use>',
    '</svg></i></button>',
]).join('');

export default class {
    constructor(
        parent,
        project,
    ) {
        this.el = document.createElement('article');
        this.el.className = 'project-card';

        const swiperId = `swiper-${project.id}`
        if (project.images) {
            const imageCont = document.createElement('div');
            imageCont.className = 'project-card__image-cont';
            this.el.appendChild(imageCont);

            const swiper = document.createElement('div');
            swiper.className = 'swiper-container';
            swiper.id = swiperId;
            this.el.appendChild(swiper);

            const images = document.createElement('ul');
            images.className = 'swiper-wrapper';
            swiper.appendChild(images);
            let i;
            let l;
            let li;
            for (i = 0, l = project.images.length; i<l; i += 1) {
                li = document.createElement('li');
                li.className = 'swiper-slide project-images__item';
                li.innerHTML = `<a class="project-images__image-cont" href="${project.url}" title="${project.title}" style="background-image:url('${project.images[i]}')"></a>`;
                images.appendChild(li);
            }

            const buttons = document.createElement('div');
            buttons.className = 'direction-buttons';
            buttons.innerHTML = swiperButtons;
            swiper.appendChild(buttons);
        }

        const link = document.createElement('a');
        link.className = 'project-card__text';
        link.setAttribute('href', project.url);
        link.setAttribute('title', project.title);
        this.el.appendChild(link);

        const title = document.createElement('h4');
        title.innerHTML = project.title;
        link.appendChild(title);

        const subtitle = document.createElement('h5');
        subtitle.innerHTML = project.subtitle;
        link.appendChild(subtitle);

        const location = document.createElement('p');
        location.innerHTML = `<i class="icon icon-compass-down"><svg><use href="/images/sprites/sprites.svg#icon-compass-down"></use></svg></i> ${project.location}`;
        link.appendChild(location);

        // add this card to the DOM
        parent.appendChild(this.el);

        // init slideshow
        if (project.images) {
            this.projectImages = new ProjectImages({
                id: swiperId,
                isHero: false,
                prevHandle: '.project-images__prev',
                nextHandle: '.project-images__next',
            });
        } else {
            this.projectImages = null;
        }
    }

    destroy () {
        if (this.projectImages) {
            this.projectImages.destroy();
        }
        this.el.parentNode.removeChild(this.el);
        delete this.el;
        delete this.projectImages;
    }

}
