// import { events, emitEvent } from '../utilities/custom-events';
import { events, emitEvent } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';

export default class {
    constructor({
        id,
        closeHandle,
        contentHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            emitEvent(events.closeSidebar);
        }
        function handleOpenSidebar(e) {
            const {
                contentBg = 'default',
                size = 'md',
                position = 'left',
                overlay = false,
            } = e.detail;

            el.setAttribute('data-content-bg', contentBg);
            el.setAttribute('data-size', size);
            el.setAttribute('data-position', position);
            el.setAttribute('data-overlay', overlay);
            emitEvent(events.lockScroll);

            // NOTE: setTimeout is to fix issue with CSS transition on position:right version of sidebar
            setTimeout(() => {
                el.classList.add(activeClass);
                document.addEventListener('keyup', handleKeyup);
            }, 250);
        }
        function handleCloseSidebar() {
            emitEvent(events.unlockScroll);
            el.classList.remove(activeClass);

            document.removeEventListener('keyup', handleKeyup);

            content.innerHTML = '';
        }
        function handleLoadSidebar(e) {
            const {
                markup,
                contentBg = 'default',
                size = 'md',
                position = 'left',
                overlay = false,
                cb = null,
            } = e.detail;

            injectMarkup(content, markup);
            emitEvent(events.openSidebar, { contentBg, size, position, overlay });

            if (cb) cb(content);
        }
        function handleClick() {
            emitEvent(events.closeSidebar);
        }

        // Add event listeners
        window.addEventListener(events.openSidebar, handleOpenSidebar);
        window.addEventListener(events.closeSidebar, handleCloseSidebar);
        window.addEventListener(events.loadSidebar, handleLoadSidebar);
        close.addEventListener('click', handleClick);
    }
}
