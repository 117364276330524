import GoogleMapsLoader from 'google-maps';
import { gApiKey } from '../config';
import debounce from '../utilities/debouncer';
import { scrollTop } from '../utilities/scroll';

GoogleMapsLoader.KEY = gApiKey;

export default class {
    constructor({
        id,
        /* resultsContHandle, */
        formContHandle,
        mapHandle,
        installerHandle,
        activeClass,
        /* fixedClass, */
        /* pastClass, */
        state,
    }) {
        const el = document.getElementById(id);
        /* const resultsCont = el.querySelector(resultsContHandle); */
        const formCont = el.querySelector(formContHandle);
        const mapEl = el.querySelector(mapHandle);
        const installers = el.querySelectorAll(installerHandle);

        // State variables
        let map = null;
        let bounds = null;
        const markers = [];

        // Helper functions
        function setActiveIndex(i) {
            Array.from(installers).forEach((installer, ii) => {
                // TODO: Need correct icons
                let icon = 'inactive';

                if (ii === i) {
                    icon = 'active';

                    installer.classList.add(activeClass);
                    scrollTop(installer, state.headerHeight + formCont.offsetHeight);
                } else {
                    installer.classList.remove(activeClass);
                }

                markers[ii].setIcon(`/images/icon-map-${icon}.png`);
            });
        }

        // Event handler functions
        /**
         * Deprecated function in favor of hiding footer on locator reuslts page.
         * If footer need to come back uncomment this function and update state
         * classes in SASS file.
         * function handleScroll() {
         *   if (resultsCont.getBoundingClientRect().top < state.headerHeight) {
         *     el.classList.add(fixedClass);
         *   } else {
         *     el.classList.remove(fixedClass);
         *   }

         *   if (resultsCont.getBoundingClientRect().bottom < window.innerHeight) {
         *     el.classList.add(pastClass);
         *   } else {
         *     el.classList.remove(pastClass);
         *   }
         * }
         */
        const handleResize = debounce(() => {
            map.fitBounds(bounds);
        }, 500);
        const handleClicks = Array.from(installers).map((installer, i) => () => {
            setActiveIndex(i);
        });

        // Add event listeners
        /**
         * Deprecated in favor of hiding footer
         * window.addEventListener('scroll', handleScroll);
         */
        window.addEventListener('resize', handleResize);
        Array.from(installers).forEach((installer, i) => {
            installer.addEventListener('click', handleClicks[i]);
        });

        // Initialize
        GoogleMapsLoader.load(google => {
            bounds = new google.maps.LatLngBounds();
            map = new google.maps.Map(mapEl, {
                center: new google.maps.LatLng(0, 0),
                zoom: 16,
                mapTypeControl: false,
                scrollwheel: false,
                styles: [{ stylers: [{ saturation: -100, lightness: 100 }] }], // Grayscale
            });
            if (installers.length === 0) return;

            Array.from(installers).forEach((installer, i) => {
                const name = installer.getAttribute('data-name');
                const lat = installer.getAttribute('data-lat');
                const lng = installer.getAttribute('data-lng');

                const position = new google.maps.LatLng(parseFloat(lat, 10), parseFloat(lng, 10));
                // TODO: Need correct icons
                const marker = new google.maps.Marker({
                    animation: google.maps.Animation.DROP,
                    position,
                    title: name,
                    icon: '/images/icon-map-inactive.png',
                });

                // Keep array of markers for handling click events
                markers.push(marker);

                // Set icons and scroll to new active chapter list item on click
                marker.addListener('click', () => {
                    setActiveIndex(i);
                });

                // Set marker on map and extend bounds
                marker.setMap(map);
                bounds.extend(position);
            });

            map.fitBounds(bounds);
        });
    }
}
