import { getBreakpoint } from '../utilities/breakpoints';
import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        heroHandle,
        navHandle,
        state,
    }) {
        // Only portrait orientation of small viewports
        if (window.innerHeight < window.innerWidth || getBreakpoint() !== 'sm') return;

        // Elements and class variables
        const el = document.getElementById(id);
        const hero = el.querySelector(heroHandle);
        const nav = el.querySelector(navHandle);

        // Event handler functions
        const handleResize = debounce(() => {
            const vh = Math.min(window.innerHeight, document.documentElement.clientHeight);
            const height = vh - state.headerHeight - nav.offsetHeight;

            hero.style.height = `${height}px`;
        }, 500);

        // Add event listeners
        window.addEventListener('resize', handleResize);

        // Initialize
        handleResize();
    }
}
