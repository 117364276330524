import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';
import { scrollTo } from '../utilities/scroll';

export default class {
    constructor({
        id,
        lockedClass,
        getLatLng = false,
        state,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const links = el.querySelectorAll('a');
        const anchors = Array.from(links).filter(link => link.getAttribute('data-anchor'));
        const videos = Array.from(links).filter(link => link.href.includes('/videos/'));

        // Event handler functions
        function handleTouchMove() {
            window.scrollTo(0, state.lastY);
        }
        function handleLockScroll() {
            document.body.classList.add(lockedClass);

            state.lastY = window.pageYOffset;

            // Prevent scroll on iOS
            document.addEventListener('touchmove', handleTouchMove);
        }
        function handleUnlockScroll() {
            document.body.classList.remove(lockedClass);
            // Enable scroll on iOS
            document.removeEventListener('touchmove', handleTouchMove);
        }
        const handleAnchors = Array.from(anchors).map(anchor => e => {
            const hash = anchor.href.split('#')[1];
            if (document.getElementById(hash) !== null) {
                e.preventDefault();
                scrollTo(hash, state.headerHeight);
            }
        });
        const handleVideos = Array.from(videos).map(video => e => {
            e.preventDefault();

            const url = video.href;

            function cb(res) {
                const markup = res;

                emitEvent(events.loadModal, { markup });
            }

            get({ url, cb });
        });

        // Add event listeners
        window.addEventListener(events.lockScroll, handleLockScroll);
        window.addEventListener(events.unlockScroll, handleUnlockScroll);
        Array.from(anchors).forEach((anchor, i) => {
            anchor.addEventListener('click', handleAnchors[i]);
        });
        Array.from(videos).forEach((video, i) => {
            video.addEventListener('click', handleVideos[i]);
        });

        // Get lat lng from HTML5 geolocation
        if (getLatLng && 'geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                const url = window.location.href;
                const latlng = `${coords.latitude},${coords.longitude}`;

                function redirect() {
                    window.location.href = `${url}${url.includes('?') ? '&' : '?'}latlng=${latlng}`;
                }

                // Retrun if latlng already set
                if (url.includes('zip=') || url.includes('latlng=')) return;

                /* eslint-disable no-undef */
                if (typeof ga === 'function') {
                    ga('send', 'event', {
                        eventCategory: 'Find an Installer',
                        eventAction: 'submit',
                        eventLabel: 'Installer Locator Page - Redirect',
                        hitCallback: redirect,
                        hitCallbackFail: redirect,
                    });
                } else {
                    redirect();
                }
                /* eslint-enable no-undef */
            });
        }
    }
}
