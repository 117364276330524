export default class {
    constructor() {
        this.xhr = null;
        this.parser = new DOMParser();
    }

    destroy() {
        this.killRequest();
        delete this.xhr;
        delete this.parser;
    }

    getPage(url, callback) {
        const self = this;

        this.killRequest();

        this.xhr = new XMLHttpRequest();
        this.xhr.onload = () => {
            if (self.xhr.status === 200) {
                callback(self.xhr.responseText);
            } else {
                callback(null);
            }
        };
        this.xhr.onerror = () => {
            callback(null);
        };

        this.xhr.open('GET', url, true);
        this.xhr.setRequestHeader('Content-type', 'text/html');
        this.xhr.send();
    }

    getPartial(selector, url, callback) {
        const self = this;

        this.getPage(url, (page) => {
            if (page === null) {
                callback(null, null);
            } else {
                const xmlDoc = self.parser.parseFromString(page, 'text/html');
                const title = xmlDoc.title;

                // strip script tags
                const scripts = Array.prototype.slice.call(xmlDoc.getElementsByTagName('script'), 0);
                scripts.forEach((tag) => {
                    tag.parentNode.removeChild(tag);
                });

                const html = xmlDoc.querySelector(selector).innerHTML;
                callback({ title, html });
            }
        });
    }

    killRequest() {
        if (this.xhr) {
            this.xhr.abort();
            this.xhr = null;
        }
    }
}
