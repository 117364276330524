export default class {
    constructor() {
        function handleMessage({ data }) {
            const { event } = data;

            /* eslint-disable no-undef */
            if (event === 'calendly.event_scheduled' && dataLayer) {
                dataLayer.push({
                    event: 'veluxEventsDataLayer',
                    eventCategory: 'Design Consultation',
                    eventAction: 'Scheduling',
                    eventLabel: 'Scheduling Complete',
                });
            }
            /* eslint-enable no-undef */
        }

        window.addEventListener('message', handleMessage);
    }
}
