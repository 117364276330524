import Swiper from 'swiper';

export default class {
    constructor({
        id,
        isHero,
        prevHandle,
        nextHandle,
    }) {
        const el = document.getElementById(id);

        this.buttonNext = el.querySelector(nextHandle);
        this.buttonPrev = el.querySelector(prevHandle);
        this.buttonNextClick = this.buttonNextClick.bind(this);
        this.buttonPrevClick = this.buttonPrevClick.bind(this);
        this.buttonNext.addEventListener('click', this.buttonNextClick);
        this.buttonPrev.addEventListener('click', this.buttonPrevClick);

        const config = {
            allowTouchMove: false,
            loop: false,
            slidePerView: 1,
            slideToClickedSlide: false,
            threshold: 1,
        };
        if (isHero) {
            config.allowTouchMove = true;
            config.grabCursor = true;
            config.slidesOffsetAfter = 30;
            config.slidesOffsetBefore = 30;
            config.slidesPerView = 'auto';
            config.spaceBetween = 10;
            config.threshold = 1;
            config.breakpoints = {
                1250: {
                    slidesOffsetAfter: 40,
                    slidesOffsetBefore: 40,
                    spaceBetween: 20,
                },
            };
        }
        this.swiper = new Swiper(el, config);
        this.swiperSlideChange = this.swiperSlideChange.bind(this);
        this.swiper.on('slideChange', this.swiperSlideChange);
        this.swiper.on('reachBeginning', this.swiperSlideChange);
        this.swiper.on('reachEnd', this.swiperSlideChange);
        this.swiper.on('resize', this.swiperSlideChange);

        this.buttonPrev.classList.add('disabled');
        if (this.swiper.slides.length === 1) {
            this.buttonNext.classList.add('disabled');
        }
    }

    destroy() {
        this.buttonNext.removeEventListener('click', this.buttonNextClick);
        this.buttonPrev.removeEventListener('click', this.buttonPrevClick);
        this.swiper.off('slideChange', this.swiperSlideChange);
        this.swiper.off('reachBeginning', this.swiperSlideChange);
        this.swiper.off('reachEnd', this.swiperSlideChange);
        this.swiper.off('resize', this.swiperSlideChange);
        this.swiper.destroy();
        delete this.buttonNext;
        delete this.buttonPrev;
        delete this.swiper;
    }

    swiperSlideChange(event) {
        const index = event.realIndex;
        if (index <= 0) {
            this.buttonPrev.classList.add('disabled');
        } else {
            this.buttonPrev.classList.remove('disabled');
        }
        if (this.swiper.isEnd) {
            this.buttonNext.classList.add('disabled');
        } else {
            this.buttonNext.classList.remove('disabled');
        }
    }

    buttonNextClick(event) {
        event.preventDefault();
        this.swiper.slideNext();
    }

    buttonPrevClick(event) {
        event.preventDefault();
        this.swiper.slidePrev();
    }
}
