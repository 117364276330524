import { get } from '../utilities/router';
import { load } from 'recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6Lco6QYbAAAAAOLwnHxNvz71x4hWcO-E-AtIfwji';

export default class {
    constructor({
        id,
        productsSectionHandle,
        addHandle,
        index,
        productFieldsTemplate,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const productSection = el.querySelector(productsSectionHandle);
        const productList = productSection.querySelector('ul');
        const removes = productList.querySelectorAll('li button');
        const add = el.querySelector(addHandle);
        const submit = form.querySelector('[type="submit"]');

        let recaptcha = null;

        // Event handler functions
        function handleRemove(e) {
            const button = e.currentTarget;
            // Get list item surrounding the button
            const listItem = button.closest('li');

            // Remove list item
            listItem.parentNode.removeChild(listItem);
        }

        function handleAdd() {
            const url = productFieldsTemplate;
            const query = { index };

            index++;

            function cb(res) {
                // Create new list item with response markup and add to list
                const listItem = document.createElement('li');

                listItem.innerHTML = res;
                productList.appendChild(listItem);

                // Listen for click on newly added remove button
                const remove = listItem.querySelector('button');

                remove.addEventListener('click', handleRemove);
            }

            // Fetch product fields
            get({ url, query, cb });
        }

        function handleSubmit(e) {
            if (recaptcha === null) {
                return;
            }

            e.preventDefault();

            submit.disabled = true;
            submit.value = 'Processing...';

            // Get reCAPTCHA score token
            recaptcha.execute('form').then(token => {
                // Create hidden input in form to pass reCAPTCHA score token to server
                const input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', 'token');
                input.setAttribute('value', token);

                form.appendChild(input);
                form.submit();
            });
        }

        // Add event listeners
        add.addEventListener('click', handleAdd);

        Array.from(removes).forEach(remove => {
            remove.addEventListener('click', handleRemove);
        });

        load(RECAPTCHA_SITE_KEY).then(r => {
            recaptcha = r;

            form.addEventListener('submit', handleSubmit);
        });
    }
}
