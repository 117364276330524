import Mark from 'mark.js';

export default class {
    constructor({
        id,
        emptyHandle,
        accordianHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const search = form.querySelector('[type="search"]');
        const empty = el.querySelector(emptyHandle);
        const accordians = el.querySelectorAll(accordianHandle);
        const labels = Array.from(accordians).map(a => a.querySelector('label'));

        const marks = Array.from(accordians).map(a => new Mark(a));

        function handleSearch() {
            const { value } = search;

            let hasResults = false;

            Array.from(accordians).forEach((a, i) => {
                const label = labels[i];

                function done(count) {
                    if (value !== '' && count === 0) return;

                    hasResults = true;

                    a.style.display = 'block';
                    label.classList.toggle(activeClass, value !== '');
                }

                a.style.display = 'none';
                label.classList.remove(activeClass);
                marks[i].unmark();
                marks[i].mark(value, { done });
            });

            empty.style.display = hasResults ? 'none' : 'block';
        }
        const handleLabels = Array.from(labels).map(l => () => {
            l.classList.toggle(activeClass);
        });

        form.addEventListener('submit', handleSearch);
        search.addEventListener('keyup', handleSearch);
        labels.forEach((l, i) => {
            l.addEventListener('click', handleLabels[i]);
        });

        // Initiate
        handleSearch();
    }
}
