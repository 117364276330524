/**
 * API documention for Vimeo player:
 * https://github.com/vimeo/player.js
 */

import Player from '@vimeo/player';
import { events, emitEvent } from '../utilities/custom-events';

const IS_TOUCH = 'ontouchstart' in window;
const EVENTS = {
    POINTER_DOWN: IS_TOUCH ? 'touchstart' : 'mousedown',
    POINTER_MOVE: IS_TOUCH ? 'touchmove' : 'mousemove',
    POINTER_UP: IS_TOUCH ? 'touchend' : 'mouseup',
    POINTER_OUT: IS_TOUCH ? 'touchleave' : 'mouseout',
};

export default class {
    constructor({
        id,
        playPauseHandle,
        timeElapsedHandle,
        progressContHandle,
        progressHandle,
        pausedClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const iframe = el.querySelector('iframe');
        const playPause = el.querySelector(playPauseHandle);
        const timeElapsed = el.querySelector(timeElapsedHandle);
        const progressCont = el.querySelector(progressContHandle);
        const progress = el.querySelector(progressHandle);

        const player = new Player(iframe);

        // State variables
        let duration = null;
        let playing = false;
        let dragging = false;

        // Helper functions
        function playVideo() {
            player.play();

            playing = true;

            el.classList.remove(pausedClass);
        }
        function pauseVideo() {
            player.pause();

            playing = false;

            el.classList.add(pausedClass);
        }
        function updateTimeElapsed(time) {
            const mins = Math.floor(time / 60);
            const secs = Math.floor(time % 60);
            timeElapsed.innerHTML = `${mins}:${secs < 10 ? '0' : ''}${secs}`;
        }
        function updateProgress(percent) {
            progress.style.transform = `scaleX(${percent})`;
        }

        // Event handler functions
        function handleCloseModal(e) {
            e.preventDefault();

            pauseVideo();
        }
        function handlePlayPause() {
            if (playing) {
                pauseVideo();
            } else {
                playVideo();
            }
        }
        function handlePointerMove(e) {
            e.stopPropagation();

            if (!dragging) return;

            // Scale progress bar
            const offset = e.clientX - progressCont.getBoundingClientRect().left;
            const percent = offset / progressCont.offsetWidth;
            const seconds = duration * percent;

            updateProgress(percent);
            updateTimeElapsed(seconds);
        }
        function handlePointerDown() {
            pauseVideo();

            // Update state variables
            dragging = true;

            progressCont.addEventListener(EVENTS.POINTER_MOVE, handlePointerMove);
        }
        function handlePointerUp(e) {
            if (!dragging) return;

            // Update state variables
            dragging = false;

            // Set new time
            const offset = e.clientX - progressCont.getBoundingClientRect().left;
            const percent = offset / progressCont.offsetWidth;
            const seconds = duration * percent;

            player.setCurrentTime(seconds);
            updateProgress(percent);
            updateTimeElapsed(seconds);
            playVideo();

            progressCont.removeEventListener(EVENTS.POINTER_MOVE, handlePointerMove);
        }
        function handleTimeUpdate({ seconds: s, percent: p }) {
            updateProgress(p);
            updateTimeElapsed(s);
        }
        function handleEnded() {
            emitEvent(events.closeModal);
        }

        // Add event listeners
        window.addEventListener(events.closeModal, handleCloseModal);
        playPause.addEventListener('click', handlePlayPause);
        progressCont.addEventListener(EVENTS.POINTER_DOWN, handlePointerDown);
        progressCont.addEventListener(EVENTS.POINTER_UP, handlePointerUp);
        progressCont.addEventListener(EVENTS.POINTER_OUT, handlePointerUp);
        player.on('timeupdate', handleTimeUpdate);
        player.on('ended', handleEnded);

        // Set video duration
        player.getDuration().then(d => {
            duration = d;
        });

        // Initialize
        playVideo();
    }
}
