export const events = {
    showHeader: 'show-header',
    hideHeader: 'hide-header',
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    openModal: 'open-modal',
    closeModal: 'close-modal',
    loadModal: 'load-modal',
    openSidebar: 'open-sidebar',
    closeSidebar: 'close-sidebar',
    loadSidebar: 'load-sidebar',
};

/**
 * Emit event - wrapper around CustomEvent API
 * @param {string} eventHandle
 * @param {object} eventDetails
 */
export function emitEvent(eventHandle, eventDetails) {
    const event = new CustomEvent(eventHandle, { detail: eventDetails });

    window.dispatchEvent(event);
}
