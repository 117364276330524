export default class {
    constructor({
        id,
        imageHandle,
        colorHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const image = el.querySelector(imageHandle);
        const colors = el.querySelectorAll(colorHandle);

        // Event handler functions
        const handleClicks = Array.from(colors).map((color, i) => () => {
            const src = color.getAttribute('data-image-src');

            Array.from(colors).forEach((c, ii) => {
                if (ii === i) {
                    c.classList.add(activeClass);
                } else {
                    c.classList.remove(activeClass);
                }
            });

            image.setAttribute('src', src);
        });

        // Add event listeners
        Array.from(colors).forEach((color, i) => {
            color.addEventListener('click', handleClicks[i]);
        });
    }
}
