import { events, emitEvent } from '../utilities/custom-events';
import { anchorSpy } from '../utilities/spy';
import { scrollTo } from '../utilities/scroll';

const HEADER_NAV_HANDLE = '.header__nav';
const HEADER_MENU_HANDLE = '.header__menu';

export default class {
    constructor({
        id,
        toggleInputHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const toggleInput = el.querySelector(toggleInputHandle);
        const headerNav = el.querySelector(HEADER_NAV_HANDLE);
        const headerNavLinks = headerNav.querySelectorAll('nav a');
        const headerMenu = el.querySelector(HEADER_MENU_HANDLE);
        const headerMenuLinks = headerMenu.querySelectorAll('a');
        const headerCtaLink = headerNav.querySelector('a[href*="#enter"]');
        const linkSections = Array.from(headerNavLinks)
            .map(link => document.getElementById(`${link.href.split('#')[1]}`))
            .filter(link => link);

        // Event handler functions
        function handleToggle() {
            if (toggleInput.checked) {
                el.classList.add(activeClass);
                emitEvent(events.lockScroll);
            } else {
                el.classList.remove(activeClass);
                emitEvent(events.unlockScroll);
            }
        }
        function closeMenu() {
            toggleInput.checked = false;
            handleToggle();
        }
        function handleScroll() {
            // Mark active section
            const threshold = window.innerHeight / 3;
            anchorSpy(headerNavLinks, linkSections, threshold, activeClass);
        }
        const handleClicks = Array.from(headerNavLinks).map(link => e => {
            const linkSection = link.href.split('#')[1];
            if (document.getElementById(linkSection) !== null) {
                e.preventDefault();
                scrollTo(linkSection);
            }
        });
        function handleCtaClick(e) {
            const linkSection = headerCtaLink.href.split('#')[1];
            if (document.getElementById(linkSection) !== null) {
                e.preventDefault();
                scrollTo(linkSection);
            }
        }

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        toggleInput.addEventListener('change', handleToggle);
        Array.from(headerNavLinks).forEach((link, i) => {
            link.addEventListener('click', handleClicks[i]);
        });
        if (headerCtaLink) {
            headerCtaLink.addEventListener('click', handleCtaClick);
        }
        Array.from(headerMenuLinks).forEach(link => {
            link.addEventListener('click', closeMenu);
        });
    }
}
