import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const image = el.querySelector('img');

        function handleLoad() {
            image.style.width = '100%';
            image.style.height = 'auto';

            if (image.offsetHeight > window.innerHeight) {
                image.style.width = 'auto';
                image.style.height = '100%';
            }
        }
        const handleResize = debounce(handleLoad, 300);

        image.addEventListener('load', handleLoad);
        window.addEventListener('resize', handleResize);
    }
}
