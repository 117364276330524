export default function () {
    const links = document.querySelectorAll('a[href*="livechat"]:not([data-ready="true"])');
    function handleClick(e) {
        e.preventDefault();
        /* eslint-disable no-undef */
        if (LC_API) {
            // https://developers.livechat.com/docs/extending-chat-widget/javascript-api/v1.0#open-the-chat-window
            LC_API.open_chat_window();
        }
        /* eslint-enable no-undef */
    }
    links.forEach(l => {
        l.setAttribute('data-ready', true);
        l.addEventListener('click', handleClick);
    });
}
