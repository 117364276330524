import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        slideHandle,
        controlsHandle,
        state,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const slides = el.querySelectorAll(slideHandle);
        const controls = el.querySelector(controlsHandle);

        // Event handler functions
        const handleResize = debounce(() => {
            const vh = Math.min(window.innerHeight, document.documentElement.clientHeight);
            const height = vh - state.headerHeight - controls.offsetHeight;

            Array.from(slides).forEach(slide => { slide.style.height = `${height}px`; });
        }, 500);

        // Add event listeners
        window.addEventListener('resize', handleResize);

        // Initialize
        handleResize();
    }
}
