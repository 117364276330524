const TAGS = 'h1,h2,h3,h4,h5,h6,p,strong,b,em,li,a,span,td,button';
const CHILD_TAGS = 'BR,IMG,EM,SPAN';

/*
 * Search against tags and replace characters and phrases with specific markup
 * @return {void}
 */
export default function () {
    Array.from(document.querySelectorAll(TAGS)).forEach(el => {
        const childCheck = el.children.length === 1 && CHILD_TAGS.includes(el.children[0].nodeName);

        if (el.children.length === 0 || childCheck) {
            el.innerHTML = el.innerHTML.split('®').join('<sup>&reg;</sup>');
            el.innerHTML = el.innerHTML.split('Fresh Air').join('<span class="nowrap">Fresh Air</span>');
        }
    });
}
