import { events, emitEvent } from '../utilities/custom-events';
import HtmlLoader from '../utilities/html-loader';
import ImageComparison from './image-comparison';
import ProjectImages from './project-images';

function supportsHistoryAPI() {
    return !!(window.history && window.history.pushState && window.history.replaceState);
}

export default class {
    constructor(
        element,
    ) {
        this.baseUrl = `${window.location.origin}/projects/`;

        this.htmlLoader = new HtmlLoader();
        this.imageComparison = null;
        this.projectImages = null;

        this.handleCloseSidebar = this.handleCloseSidebar.bind(this);
        this.historyPopState = this.historyPopState.bind(this);
        this.linkClicked = this.linkClicked.bind(this);
        this.sidebarLoaded = this.sidebarLoaded.bind(this);
        this.htmlLoaded = this.htmlLoaded.bind(this);

        this.spinner = document.createElement('div');
        this.spinner.className = 'spinner-overlay';
        document.body.appendChild(this.spinner);

        if (supportsHistoryAPI()) {
            window.addEventListener('popstate', this.historyPopState);
        }
        window.addEventListener(events.closeSidebar, this.handleCloseSidebar);
        element.addEventListener('click', this.linkClicked);
    }

    isProjectPage(href) {
        if (href && href.indexOf(this.baseUrl) === 0) {
            const path = href.replace(this.baseUrl, '');
            if (path && path.indexOf('map') !== 0) {
                return true;
            }
        }
        return false;
    }

    handleCloseSidebar() {
        this.cleanupOld();
        const href = window.location.href;
        if (this.isProjectPage(href) && supportsHistoryAPI()) {
            history.back();
        }
    }

    historyPopState() {
        const href = window.location.href;
        if (this.isProjectPage(href)) {
            this.showModal(href);
        } else {
            this.closeModal();
        }
    }

    linkClicked(event) {
        // get appropriate element
        let link = event.target;
        while (link.parentNode) {
            if (link.nodeName === 'A' || link.nodeName === 'BUTTON' || link.nodeName === 'INPUT' || link.nodeName === 'SELECT' || link.nodeName === 'TEXTAREA') {
                // stop once we've reached an interactive element
                break;
            }
            link = link.parentNode
        }

        // get link's URL and show modal when appropriate
        if (link.nodeName === 'A') {
            const href = link.href;
            if (this.isProjectPage(href)) {
                event.preventDefault();
                if (supportsHistoryAPI()) {
                    history.pushState(null, null, href);
                }
                this.showModal(href);
            }
        }
    }

    cleanupOld() {
        this.htmlLoader.killRequest();
        this.spinner.classList.remove('show');
        if (this.projectImages) {
            this.projectImages.destroy();
            this.projectImages = null;
        }
        if (this.imageComparison) {
            this.imageComparison.destroy();
            this.imageComparison = null;
        }
    }

    closeModal() {
        this.cleanupOld();
        emitEvent(events.closeSidebar);
    }

    showModal(href) {
        this.spinner.classList.add('show');
        this.htmlLoader.getPartial('.main', href, this.htmlLoaded);
    }

    htmlLoaded({ html }) {
        this.cleanupOld();
        emitEvent(events.loadSidebar, { contentBg: 'white', markup: html, position: 'right', size: 'lg', cb: this.sidebarLoaded });
    }

    sidebarLoaded(container) {

        // hide spinner
        this.spinner.classList.remove('show');

        // init project images
        const imagesElement = container.querySelector('.project-images');
        if (imagesElement) {
            this.projectImages = new ProjectImages({
                id: imagesElement.id,
                isHero: true,
                prevHandle: '.project-images__prev',
                nextHandle: '.project-images__next',
            });
        }

        // init image comparison
        const comparisonElement = container.querySelector('.image-comparison');
        if (comparisonElement) {
            // NOTE: the setTimeout is to fix issue with ImageComparison's sizes not computed initially
            const self = this;
            setTimeout(() => {
                self.imageComparison = new ImageComparison({
                    id: comparisonElement.id,
                    resizeHandle: '.image-comparison__resize',
                    sliderHandle: '.image-comparison__slider',
                });
            }, 500);
        }
    }


}
