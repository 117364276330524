import debounce from '../utilities/debouncer';

export default class {
    constructor({
        id,
        imageHandle,
        infoHandle,
        navHandle,
        state,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const images = el.querySelectorAll(imageHandle);
        const info = el.querySelector(infoHandle);
        const nav = el.querySelector(navHandle);

        // Event handler functions
        const handleResize = debounce(() => {
            const vh = Math.min(window.innerHeight, document.documentElement.clientHeight);
            let height = vh - state.headerHeight;
            height -= info !== null ? info.offsetHeight : 0;
            height -= nav !== null ? nav.offsetHeight : 0;

            Array.from(images).forEach(image => { image.style.height = `${height}px`; });
        }, 500);

        // Add event listeners
        window.addEventListener('resize', handleResize);

        // Initialize
        handleResize();
    }
}
