export default class {
    constructor({
        id,
        dollarHandle,
        buttonHandle,
        afterBarHandle,
        activeClass,
        steps,
    }) {
        const el = document.getElementById(id);
        const dollars = el.querySelectorAll(dollarHandle);
        const range = el.querySelector('input[type="range"]');
        const afterBar = el.querySelector(afterBarHandle);
        const button = el.querySelector(buttonHandle);

        // Event handler functions
        function handleInput() {
            Array.from(dollars).forEach((dollar, i) => {
                if (i < range.value) {
                    dollar.classList.add(activeClass);
                } else {
                    dollar.classList.remove(activeClass);
                }
            });

            const ratio = range.value / steps;
            const buttonOffset = button.offsetWidth * ratio;

            afterBar.style.width = `${100 * (1 - ratio)}%`;
            button.style.left = `calc(${100 * ratio}% - ${buttonOffset}px)`;
        }

        // Add event listeners
        range.addEventListener('input', handleInput);
    }
}
